<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_235_20725" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_235_20725)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.3881 21H4.61194C3.7165 21 3 20.2835 3 19.3881V4.61194C3 3.7165 3.7165 3 4.61194 3H19.3881C20.2835 3 21 3.7165 21 4.61194V19.3881C21 20.2835 20.2835 21 19.3881 21ZM19.791 4.81343C19.791 4.47768 19.5224 4.20892 19.1864 4.20892L4.81331 4.20907C4.47756 4.20907 4.2088 4.47768 4.2088 4.81359V19.2091C4.2088 19.5449 4.4774 19.8136 4.81331 19.8136H19.2088C19.5446 19.8136 19.8134 19.545 19.8134 19.2091V4.81359L19.791 4.81343ZM10.791 16.2537C10.4552 16.5895 9.91783 16.5895 9.58209 16.2537L5.95526 12.6046C5.61951 12.2688 5.61951 11.7315 5.95526 11.3957C6.29102 11.06 6.8284 11.06 7.16414 11.3957L10.1866 14.4405L16.8359 7.76886C17.1716 7.4331 17.709 7.4331 18.0447 7.76886C18.3805 8.10461 18.3805 8.64199 18.0447 8.97773L10.791 16.2537Z"
            fill="black" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCheckboxFilled"
};
</script>

<style scoped>

</style>