<template>
  <VMask class="d-flex align-items-center justify-content-center">
    <VBox
      class="popup-confirm"
      :class="[`popup-confirm--${size}`]"
    >
      <VOverlay v-if="loading">
        <VLoader />
      </VOverlay>

      <div class="popup-confirm__btn-close" @click="onClosePopup">
        <icon-close />
      </div>
      <div class="popup-confirm__title">
        <slot name="title">
          title
        </slot>
      </div>
      <div class="popup-confirm__body">
        <slot name="body">
          body
        </slot>
      </div>
      <div class="d-flex justify-content-end">
        <VButton
          type="orange-outline"
          class="btn-xl mr-1"
          @click="onClosePopup"
        >
          No
        </VButton>
        <VButton
          type="orange"
          class="btn-xl"
          @click="$emit('confirm')"
        >
          Yes
        </VButton>
      </div>
    </VBox>
  </VMask>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VMask from '@/js/components/common/VMask';
import IconClose from '@/js/components/icons/IconClose';
import { requestStatus } from '@/js/utils/constants';
import { bodyOverflow, cancelOverflow } from '@/js/utils/helpers';

export default {
  name: "PopupConfirm",
  components: {
    IconClose,
    VBox,
    VMask,
  },
  props: {
    loading: Boolean,
    size: {
      type: String,
      default: 'md'
    },
  },
  data: () => ({
    requestStatus,
  }),
  mounted() {
    bodyOverflow();
  },
  methods: {
    onClosePopup() {
      this.$emit('cancel');
    }
  },
  destroyed() {
    cancelOverflow();
  }
};
</script>

<style lang="scss" scoped>
.popup-confirm {
  position: relative;
  padding: 40px;

  &--sm {
    max-width: 400px;
    padding: 24px;
  }

  &--md {
    max-width: 574px;
  }

  &__btn-close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
  }

  &__body {
    margin-bottom: 40px;
  }
}
</style>