<template>
  <label
    class="v-checkbox"
    :class="{'v-checkbox--disabled': disabled}"
  >
    <input
      type="checkbox"
      class="v-checkbox__field"
      :disabled="disabled"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <div class="v-checkbox__checkmark">
      <IconCheckbox v-if="!checked"/>
      <IconCheckboxFilled v-else />
    </div>
  </label>
</template>

<script>
import IconCheckbox from '@/js/components/icons/IconCheckbox';
import IconCheckboxFilled from '@/js/components/icons/IconCheckboxFilled';

export default {
  name: "VCheckbox",
  components: { IconCheckboxFilled, IconCheckbox },
  props: {
    name: String,
    checked: Boolean,
    disabled: Boolean,
  }
};
</script>

<style lang="scss" scoped>
.v-checkbox {
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0; // grow
  cursor: pointer;

  &__field {
    position: absolute;
    opacity: 0;
  }

  &__checkmark {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &--disabled {
    cursor: auto;
    opacity: .5;
  }
}
</style>