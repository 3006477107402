<template>
  <div class="v-box">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VBox"
};
</script>

<style lang="scss" scoped>
.v-box {
  width: 100%;
  padding: 24px;
  background: var(--white-main);
  border-radius: 10px;
  box-shadow: var(--main-box-shadow);
}
</style>